import React, { useEffect, useState, useRef } from "react";
import Globe from 'react-globe.gl';


function Location() {
    window.document.title = "Location";
    const globeEl = useRef();

    const labelsTopOrientation = new Set(['India', 'Dubai']); // avoid label collisions
    const [polygonData, setPolygonData] = useState([]);
    const [hoverD, setHoverD] = useState();



    useEffect(() => {

        fetch('data/states.json').then(response => response.json())
            .then((data) => {
                setPolygonData(data);

            });
        // setLandingSites(JSON.parse(localStorage.getItem('chartdata')).locations);

        // Auto-rotate
        globeEl.current.controls().autoRotate = true;
        globeEl.current.controls().autoRotateSpeed = 0.3;
        globeEl.current.controls().Zoom = true;
        globeEl.current.pointOfView({ lat: 20.5937, lng: 78.9629, altitude: 2 });


    }, [])


    return (
        <>
            <div className="map-wrapper">
                <div className="map-inner">
                    <Globe
                        ref={globeEl}
                        //globeImageUrl="/images/earth-blue-marble.jpg"
                        //globeImageUrl="/images/earth-day.jpg"
                         globeImageUrl="/images/earth-night.jpg"
                        // globeImageUrl="/images/earth-topology.png"
                        // bumpImageUrl="/images/earth-topology.png"
                         backgroundImageUrl="/images/bg.png"
                        backgroundColor="rgba(0,0,0,0)"
                        // onZoom={false}
                        polygonAltitude={d => d === hoverD ? 0.06 : 0.01}
                        polygonSideColor={'rgba(0, 100, 0, 0.15)'}
                        polygonStrokeColor={'#111'}
                        polygonCapColor={d => d === hoverD ? 'steelblue' : d.color || 'white'}
                        showGraticules={true}
                        polygonsData={polygonData.features}
                        labelText="label"
                        labelSize={1.0}
                        labelResolution={2}
                       
                        onPolygonHover={setHoverD}
                        labelDotOrientation={d => labelsTopOrientation.has(d.label) ? 'top' : 'bottom'}
                        polygonsTransitionDuration={300}

                        polygonLabel={d => `
                <div class="tooltip-img">
                    <span>Place:${d.name}</span>
                    <span>projects: ${d.count || 0}</span>
                </div>
            `}
                        
                        
                    />
                </div>
            </div>

            
        </>
    )
}
export default Location